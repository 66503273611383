<script setup>
import "swiper/swiper-bundle.min.css";
import "swiper/components/pagination/pagination.scss";
import "@ionic/vue/css/ionic-swiper.css";
import { ref, onMounted, computed } from "vue";
import { IonCard, IonIcon, IonicSwiper } from "@ionic/vue";
import { trashOutline, camera } from "ionicons/icons";
import ApiService from "../common/service.api";
import { toastController } from "@ionic/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination, Zoom } from "swiper";

SwiperCore.use([IonicSwiper, Navigation, Pagination, Zoom]);

const images = ref([]);
const tempFiles = ref([]);
const loading = ref(false);
const currentPage = ref(1);
const currentScale = ref("page-width");
const references = ref([
  {
    urlElemento:
      "https://www.nexgenus.com/images/blogs/migrated/2019/6/15/gondola.png",
  },
  {
    urlElemento:
      "https://www.researchgate.net/profile/Harry-Kaiser/publication/227364763/figure/fig1/AS:302391250440192@1449107100155/Planogram-Examples-and-Design-Objectives-Source-ProCorp-USA-2003.png",
  },
  {
    urlElemento:
      "https://merchlogix.com/wp-content/uploads/2022/08/shelf.png.webp",
  },
]);

onMounted(() => {
  scrollDown();
});

function triggerFileUploader() {
  document.getElementById("images-uploader").click();
}

function scrollDown() {
  document
    .querySelector("#bottom-component")
    .scrollIntoView({ behavior: "smooth" });
}

function handleFileInput(event) {
  console.log("🚀 ~ file: PlanogramViewer.vue:handleFileInput ~ event:", event);
  loading.value = true;
  const promises = [];

  for (let i = 0; i < event.target.files.length; i++) {
    const formData = new FormData();
    formData.append("file", event.target.files[i]);
    promises.push(
      ApiService.post("Upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    );
  }

  Promise.all(promises)
    .then((values) => {
      values.forEach((value) => {
        images.value.push({
          urlElemento: "https://api-retail.whappy.it/Drive/" + value.data,
        });
      });
    })
    .finally(() => {
      loading.value = false;
      presentToast("File caricato");
    });
}

function deleteImage(image) {
  const index = images.value.indexOf(image);
  images.value.splice(index, 1);
}

async function presentToast(message) {
  const toast = await toastController.create({
    message: message,
    duration: 1500,
    position: "bottom",
    color: "success",
  });

  await toast.present();
}

function previusPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
}
function nextPage() {
  currentPage.value++;
}

function zoomIn() {
  currentScale.value = "page-width";
}

function zoomOut() {
  currentScale.value = "page-fit";
}
</script>
<template>
  <master-layout smallTitle="visualizzazione" bigTitle="Planogram">
    <div class="w-full flex flex-column">
      <ion-card>
        <swiper
          class="w-full"
          :slides-per-view="1"
          :loop="true"
          :pagination="{ clickable: true }"
          navigation
          :zoom="true"
        >
          <swiper-slide v-for="n in references" :key="n">
            <div class="ion-text-center swiper-zoom-container">
              <img
                :src="n.urlElemento"
                @error="handleImgError"
                style="width: max-content"
              />
            </div>
          </swiper-slide>
        </swiper>
      </ion-card>

      <div
        class="d-flex justify-content-center align-items-center p-3 h-80px mt-2"
      >
        <label v-if="loading">Caricamento delle immagini in corso...</label>

        <div v-else class="relative" @click="triggerFileUploader">
          <ion-icon
            :icon="camera"
            style="font-size: 6rem; color: var(--ion-color-primary)"
            class="absolute absolute-center"
          ></ion-icon>
          <div
            class="absolute absolute-center grid-center"
            style="
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background-color: white;
              color: var(--ion-color-primary);
            "
          >
            <span class="text-14 font-bold">{{ images.length }}/3</span>
          </div>
        </div>

        <input
          style="display: none"
          id="images-uploader"
          type="file"
          name="filefield"
          multiple
          @change="handleFileInput"
        />
      </div>

      <ion-card>
        <swiper
          v-if="images.length > 0"
          class="w-full"
          :slides-per-view="1"
          :loop="true"
          :pagination="{ clickable: true }"
          navigation
          :zoom="true"
        >
          <swiper-slide v-for="n in images" :key="n">
            <div class="ion-text-center">
              <img
                :src="n.urlElemento"
                @error="handleImgError"
                style="width: max-content"
              />
            </div>
          </swiper-slide>
        </swiper>
      </ion-card>

      <ion-card
        v-if="images.length >= 3"
        class="d-flex justify-content-center align-items-center p-3"
      >
        <button class="p-d button-primary">
          <span class="text-16">Invia</span>
        </button>
      </ion-card>
    </div>
    <div id="bottom-component"></div>
  </master-layout>
</template>

<style lang="scss" scoped>
.hello {
  position: relative;
}

.icon {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
}

.galleria {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  padding: 10px;
}

.gal-image {
  position: relative;
  max-width: 30%;
  min-width: 100px;

  img {
    width: 100%;
    height: auto;
    border: 3px solid gray;
    border-radius: 5px;
  }

  .delete-badge {
    position: absolute;
    background-color: maroon;
    color: white;
    padding: 0.6rem;
    border-radius: 50%;
    top: -10px;
    right: -10px;
  }
}

.active {
  img {
    border: 4px solid rgb(85, 194, 85);
  }
}

.relative {
  position: relative;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 900000;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  // border: 2px solid gray;
  border-radius: 10px;
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2);

  .header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .image-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 1rem 0;
  }

  .current-image {
    display: grid;
    place-content: center;
  }
}

input[type="file"] {
  border: 1px solid rgb(165, 165, 165);
  border-radius: 7px;
  padding: 1rem 2rem;
}

// .custom-file-upload {
//   border: 1px solid #ccc;
//   display: inline-block;
//   padding: 6px 12px;
//   margin-bottom: 3rem;
//   border-radius: 5px;
//   padding: 0.5rem 1rem;
//   cursor: pointer;
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06),
//     0px 2px 6px rgba(0, 0, 0, 0.12);
// }

.bottom-component {
  width: 100%;
  padding-top: 50vh;
}
</style>
